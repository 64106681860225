import { Route, Routes } from 'react-router-dom';
import Header from './components/header/Header';
import Botai from './pages/Botai';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Verifcation from './pages/verifcation';
import Activate from './pages/Activate';
import BinancePage from './pages/BinancePage';
import SideBar from './common/SideBar';
import DemoAccount from './pages/DemoAccount';
import Subscription from './pages/Subscription';
import StorePage from './pages/StorePage';
import Setting from './pages/Setting';
import WriteEmail from './components/forgetpassword/WriteEmail';
import Checkmail from './components/forgetpassword/checkmail';
import NewPassword from './components/forgetpassword/NewPassword';
import About from './pages/About';
import Contact from './pages/Contact';
import Financial from "./pages/Financial_journey";
import PlansInfo from "./pages/PlansInfo";
import Notfound from "./pages/Notfound";
import DetailPage from "./components/DetailPage";

function App() {
   return (
      <div className="App">
         <Routes>
            <Route path="/" element={<Header />} />
            <Route path="/Financial" element={<Financial />} />
            <Route path="/botai" element={<Botai />} />
            <Route path="/plans" element={<PlansInfo />} />
            <Route path="/about" element={<About />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forget-password" element={<WriteEmail />} />
            <Route path="/check-email" element={<Checkmail />} />
            <Route path="/new-password" element={<NewPassword />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/verifcation" element={<Verifcation />} />
            <Route path="/activate" element={<Activate />} />
            <Route path="/education" element={<BinancePage />} />
            <Route path="/education/dashboard" element={<SideBar />}>
               <Route path="/education/dashboard/account" element={<DemoAccount />} />
               <Route path="/education/dashboard/subscription" element={<Subscription />} />
               <Route path="/education/dashboard/store" element={<StorePage />} />
               <Route path="/education/dashboard/store/:id" element={<DetailPage />} />
               <Route path="/education/dashboard/setting" element={<Setting />} />
            </Route>
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<Notfound />} />
         </Routes>
      </div>
   );
}

export default App;
