import React from 'react';
import radioImg from '../images/radio.png';
import chartImg from '../images/chart.png';
import stockImg from '../images/stock.png';
import finiancialImg from '../images/finiancial.png';
import botassist from '../images/botassist.png';
import piechart from '../images/piechartpng.png';
import exclamation_mark from '../images/exclamation_mark.png';
import subscription from '../images/subscription.png';
import PlansCards from '../common/PlansCards';
import Questions from './Questions';
import Navbar from '../components/navbar/Navbar';
import PaymentsLogo from './PaymentsLogo';

export default function PlansInfo() {
   const plansData = [
      {
         title: 'Ideas of money',
         img: radioImg,
      },
      {
         title: 'Free one o one session ',
         img: chartImg,
      },
      {
         title: 'learn when to stop spending',
         img: stockImg,
      },
      {
         title: 'personal gain',
         img: finiancialImg,
      },
      {
         title: 'learn savings',
         img: finiancialImg,
      },
      {
         title: 'mange your Portfolio',
         img: piechart,
      },
      {
         title: 'Learn secret of money',
         img: exclamation_mark,
      },
      {
         title: 'learn how to spend money',
         img: subscription,
      },
   ];
   return (
      <div className="bg-light">
         <div className="container">
            <Navbar />
            <PlansCards />
            <div className="PlansInfo my-5">
               <p className="lead py-2">All plans include</p>
               <div className="row shadow-sm p-3">
                  {plansData.map((el, index) => {
                     return (
                        <div className="col-6 col-md-3 py-4" key={index}>
                           <img src={el.img} alt={el.title} style={{ width: 30 }} />
                           <span className="ms-3">{el.title}</span>
                        </div>
                     );
                  })}
               </div>
            </div>
         </div>
      </div>
   );
}
