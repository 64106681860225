import React from 'react'
import LoginImage from '../common/LoginImage'
import verifcationImg from '../images/verifcation.png';
import { Link } from 'react-router-dom';

export default function Verifcation() {
   return (
      <div className="container">
         <div className="row justify-content-center align-items-center min-vh-100 py-2">
            <LoginImage title="make profit using any strategy for bots" description="Connect your exchange and use bots to makeprofit" />
            <div className="col-md-4 offset-md-1 text-center">
               <div className="verification-info">
                  <h3>Second Step Verification </h3>
                  <img src={verifcationImg} className="py-3" alt="verifcationImg" loading="lazy" />
                  <p className="text-muted">Enter the verification code we sent to your Email</p>
                  <input type="text" placeholder="Type code here" className="form-control bg-body-secondary my-4" />
                  <button className="main-btn w-100 rounded my-3">Submit</button>
                  <p>
                     Didn’t get the code?
                     <Link to="/" className="main-color fw-bold">
                        {' '}
                        resend
                     </Link>
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
}
