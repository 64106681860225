import React from 'react';
import { Link } from 'react-router-dom';

export default function NewPassword() {
   return (
      <div className="bg-light">
         <div className="container min-vh-100">
            <div className="row flex-column justify-content-center align-items-center  min-vh-100">
               <div className="shadow-sm bg-white p-md-5 p-3 col-6">
                  <b>Set a new password</b>
                  <p className="lead py-2">Create a new password. Ensure it differs from previous ones for security</p>
                  <form>
                     <div className="password pb-2">
                        <label htmlFor="password" className="pb-2">
                           Password
                        </label>
                        <input type="password" className="form-control bg-body-secondary" id="passowrd" />
                     </div>
                     <div className="password pb-2">
                        <label htmlFor="conformpassword" className="pb-2">
                           Confirm Password
                        </label>
                        <input type="password" className="form-control bg-body-secondary" id="conformpassword" />
                     </div>
                  </form>
                  <Link to="/login">
                     <button className="btn-light rounded mt-3">Update Password</button>
                  </Link>
               </div>
            </div>
         </div>
      </div>
   );
}
