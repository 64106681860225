import React from 'react';
import { Link } from 'react-router-dom';
import DashboardNav from '../common/DashboardNav';
import FilterBtns from '../common/FilterBtns';

// Import the specific images based on the screenshot
import DebtManagement from '../images/Debt-Management.png';
import IncomeOptimization from '../images/Income-Optimization.png';
import InvestmentPlanning from '../images/Investment-Planning.png';
import FinancialPlanning from '../images/Financial-Planning.png';
import RetirementStrategies from '../images/Retirement-Strategies.png';
import WealthBuilding from '../images/Wealth-Building.png';
import RiskManagement from '../images/Risk-Management.png';
import TaxPlanning from '../images/Tax-Planning.png';
import EstatePlanning from '../images/Estate-Planning.png';

export default function StorePage() {
   const StoreData = [
      {
         id: 'debt-management',
         image: DebtManagement,
         title: 'Debt Management',
         info: 'Learn strategies to reduce debt and achieve financial freedom.',
         price: 'Free',
      },
      {
         id: 'income-optimization',
         image: IncomeOptimization,
         title: 'Income Optimization',
         info: 'Maximize your income and minimize taxes with smart financial planning.',
         price: 'Free',
      },
      {
         id: 'investment-planning',
         image: InvestmentPlanning,
         title: 'Investment Planning',
         info: 'Plan for long-term growth and secure your financial future.',
         price: 'Free',
      },
      {
         id: 'financial-planning',
         image: FinancialPlanning,
         title: 'Financial Planning',
         info: 'Create a comprehensive plan for your future financial goals.',
         price: 'Free',
      },
      {
         id: 'retirement-strategies',
         image: RetirementStrategies,
         title: 'Retirement Strategies',
         info: 'Explore different approaches to build a comfortable retirement fund.',
         price: 'Free',
      },
      {
         id: 'wealth-building',
         image: WealthBuilding,
         title: 'Wealth Building',
         info: 'Learn techniques to build and manage your wealth effectively.',
         price: 'Free',
      },
      {
         id: 'risk-management',
         image: RiskManagement,
         title: 'Risk Management',
         info: 'Understand and mitigate financial risks in your investments.',
         price: 'Free',
      },
      {
         id: 'tax-planning',
         image: TaxPlanning,
         title: 'Tax Planning',
         info: 'Optimize your tax strategies to retain more of your earnings.',
         price: 'Free',
      },
      {
         id: 'estate-planning',
         image: EstatePlanning,
         title: 'Estate Planning',
         info: 'Ensure your assets are transferred according to your wishes.',
         price: 'Free',
      },
   ];

   return (
      <>
         <DashboardNav />
         <div className="heading-title">
            <h5>Enhance Your Financial Knowledge with FinTechFusion</h5>
         </div>
         <FilterBtns btn1="Beginner" btn2="Intermediate" btn3="Advanced" />
         <input type="search" className="form-control" placeholder="Type to search" />
         <div className="row g-5 p-5">
            {StoreData.map((el, index) => {
               return (
                  <div className="col-md-4" key={index}>
                     <div className="card shadow-sm border-0">
                        <div className="image-card">
                           <img src={el.image} className="w-100" alt={el.title} loading="lazy" />
                        </div>
                        <div className="card-body">
                           <h5>{el.title}</h5>
                           <p className="py-1 text-muted">{el.info}</p>
                           <div className="d-flex justify-content-between align-items-center">
                              <p>{el.price}</p>
                              <Link to={`/education/dashboard/store/${el.id}`}>
                                 <button className="main-btn">Start</button>
                              </Link>
                           </div>
                        </div>
                     </div>
                  </div>
               );
            })}
         </div>
      </>
   );
}
