import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function DashboardNav() {
   const [sidebarVisible, setSidebarVisible] = useState(false);
   const navigate = useNavigate();

   function toggleSidebar() {
      setSidebarVisible(!sidebarVisible);
   }

   function handleLogout() {
      // Redirect to the home page after logout
      navigate('/');
   }

   return (
      <>
         <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
               {/* Sidebar toggle for smaller screens */}
               <Link className="navbar-brand d-lg-none d-block" onClick={toggleSidebar}>
                  <i className="fa-solid fa-bars main-color"></i>
               </Link>
               <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarContent"
                  aria-controls="navbarContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
               >
                  <span className="navbar-toggler-icon"></span>
               </button>

               <div className="collapse navbar-collapse px-2" id="navbarContent">
                  {/* Left-aligned course navigation links */}
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                     <li className="nav-item">
                        <Link className="nav-link active main-color" to="/">
                           <strong>Course Overview</strong>
                        </Link>
                     </li>
                     <li className="nav-item">
                        <Link className="nav-link" to="/education/dashboard/store/income-optimization">
                           Income Optimization
                        </Link>
                     </li>
                     <li className="nav-item">
                        <Link className="nav-link" to="/education/dashboard/store/debt-management">
                           Debt Management
                        </Link>
                     </li>
                     <li className="nav-item">
                        <Link className="nav-link" to="/education/dashboard/store/investment-planning">
                           Investment Planning
                        </Link>
                     </li>
                     <li className="nav-item">
                        <Link className="nav-link" to="/education/dashboard/store/financial-planning">
                           Financial Planning
                        </Link>
                     </li>
                  </ul>

                  {/* Right-aligned enlarged profile area */}
                  <ul className="navbar-nav ms-auto">
                     <li className="nav-item dropdown">
                        <i
                           className="fa-solid fa-user main-color fa-2x nav-link dropdown-toggle"
                           role="button"
                           data-bs-toggle="dropdown"
                           aria-expanded="false"
                           style={{ fontSize: '2rem' }} // Enlarge the profile icon
                        ></i>
                        <ul className="dropdown-menu dropdown-menu-end" style={{ fontSize: '1.1rem' }}> {/* Larger font for dropdown */}
                           <div className="email-value p-3">
                              <p className="main-color">amer@fintechfusion.net</p>
                           </div>
                           <hr />
                           <li className="d-flex justify-content-between align-items-center">
                              <Link className="dropdown-item" to="#">
                                 Dark mode
                              </Link>
                              <div className="form-check form-switch mb-0">
                                 <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                              </div>
                           </li>
                           <li className="d-flex justify-content-between align-items-center">
                              <Link className="dropdown-item" to="#">
                                 Collapse menu
                              </Link>
                              <div className="form-check form-switch mb-0">
                                 <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                              </div>
                           </li>
                           <hr />
                           <p className="main-color ps-3">Settings</p>
                           <li className="d-flex justify-content-start align-items-center">
                              <i className="fa-regular px-2 fa-bell main-color"></i>
                              <Link className="dropdown-item px-1" to="/education/dashboard/subscription">
                                 Subscription
                              </Link>
                           </li>
                           <li className="d-flex justify-content-start align-items-center">
                              <i className="fa-solid px-2 fa-user-group main-color"></i>
                              <span className="dropdown-item px-1">Invite Friends (coming soon)</span>
                           </li>
                           <li className="d-flex justify-content-start align-items-center">
                              <i className="fa-solid px-2 fa-gear main-color"></i>
                              <Link className="dropdown-item px-1" to="/education/dashboard/setting">
                                 Settings
                              </Link>
                           </li>
                           <button className="btn-light m-3 rounded" onClick={handleLogout}>Logout</button>
                        </ul>
                     </li>
                  </ul>
               </div>
            </div>
         </nav>

         {/* Additional styles for enlarged profile area */}
         <style jsx>{`
            .fa-user {
               font-size: 2.5rem; /* Enlarge the user icon */
            }
            .dropdown-menu {
               font-size: 1.1rem; /* Slightly larger font for dropdown items */
            }
            .email-value {
               font-weight: bold;
            }
         `}</style>
      </>
   );
}
