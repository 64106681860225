import React, { useState } from 'react';
import waveImg from '../images/waving-hand.png';
import { Link, useNavigate } from 'react-router-dom';

export default function Login() {
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [error, setError] = useState('');
   const navigate = useNavigate();

   const handleLogin = () => {
      if (email === 'amer@fintechfusion.net' && password === 'A12345678') {
         // Navigate to the dashboard or a protected page upon successful login
         navigate('/education/dashboard/account');
      } else {
         // Display an error message if credentials are incorrect
         setError('Invalid email or password. Please try again.');
      }
   };

   return (
      <div className="container">
         <div className="row justify-content-center align-items-center min-vh-100 py-3">
            <div className="col-md-5 offset-md-1">
               <div className="info py-3">
                  <img src={waveImg} alt="wave-img" style={{ width: 25 }} />
                  <h5 className="fw-bold py-2">Welcome back!</h5>
                  <p>Please login to access your account.</p>
               </div>
               <div className="form-inputs w-md-75 w-100">
                  <div className="email-input pb-2">
                     <label htmlFor="email" className="form-label fs-5">
                        E-mail
                     </label>
                     <input
                        type="email"
                        id="email"
                        placeholder="Type your e-mail"
                        className="form-control bg-body-secondary"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                     />
                  </div>
                  <div className="password-input pb-3">
                     <label htmlFor="password" className="form-label fs-5">
                        Password
                     </label>
                     <input
                        type="password"
                        id="password"
                        placeholder="Type your password"
                        className="form-control bg-body-secondary"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                     />
                  </div>
                  {error && <p className="text-danger">{error}</p>}
                  <p>
                     <Link className="main-color" to="/forget-password">
                        Forgot Password?
                     </Link>
                  </p>
                  <button onClick={handleLogin} className="main-btn rounded mt-3 w-100">
                     Log in
                  </button>
                  <p className="py-3">
                     Don't have an account?{' '}
                     <Link to="/signup" className="main-color fw-bold">
                        Sign Up
                     </Link>
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
}
