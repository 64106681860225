import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import Loading from './components/Loading/Loading';

const RootComponent = () => {
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setIsLoading(false);
      }, 1000);
   }, []);

   return <BrowserRouter>{isLoading ? <Loading /> : <App />}</BrowserRouter>;
};

ReactDOM.createRoot(document.getElementById('root')).render(<RootComponent />);
