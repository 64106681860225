import React from 'react';
import DashboardNav from '../common/DashboardNav';
import ChartContainer from '../common/ChartContainer';
import TokenTable from '../common/TokenTable';
import InfoBox from '../common/InfoBox';

export default function DemoAccount() {
   return (
      <>
         <DashboardNav />
         <InfoBox
            title="Empower Your Financial Future"
            info="FinTech Fusion helps you take control of your financial health. Optimize income, manage debt, and invest strategically to secure a stable financial future."
            btn1Content="Get Started"
            btn2Content="Explore Course"
            btn2Link="/education/dashboard/store"  // Link for the Explore Course button
         />
         <ChartContainer Title="Aggregate Account Balance" />
         <TokenTable />
      </>
   );
}
