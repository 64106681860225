import React from 'react';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
   return (
      <div className="col-md-4">
         <h2 className='pt-2'>{props.heading}</h2>
         <p className="text-muted py-2">{props.text}</p>
      </div>
   );
}
