import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.svg';

export default function Footer() {
   return (
      <footer className="bg-dark-color py-5 text-white">
         <div className="container">
            <div className="row">
               <div className="col-md-3">
                  <ul className="list-unstyled">
                     {/* Trading section removed */}
                  </ul>
               </div>
               <div className="col-md-3">
                  <ul className="list-unstyled">
                     <li className="fw-bold pb-2">Information</li>
                     <li className="py-2">
                        <Link href="/">FAQ</Link>
                     </li>
                     <li className="py-2">
                        <Link href="/">Blog</Link>
                     </li>
                     <li className="py-2">
                        <Link href="/">Support</Link>
                     </li>
                  </ul>
               </div>
               <div className="col-md-2">
                  <ul className="list-unstyled">
                     <li className="fw-bold pb-2 ">Company</li>
                     <li className="py-2">
                        <Link to="/about">About us</Link>
                     </li>
                     <li className="py-2">
                        <Link to="/">Careers</Link>
                     </li>
                     <li className="py-2">
                        <Link to="/contact">Contact us</Link>
                     </li>
                  </ul>
               </div>
               <div className="col-md-4">
                  <div className="col-content bg-gray-light py-4 px-5">
                     <h5>Subscribe</h5>
                     <div className="input d-flex align-items-center">
                        <input type="text" className="form-control w-75 border-0 rounded-0 " placeholder="Email address" />
                        <button className="main-btn" style={{ height: '42px' }}>
                           <i className="fa-solid fa-2x fa-arrow-right "></i>
                        </button>
                     </div>
                     <p className="py-3">
                        Hello, we are FinTech Fusion. Our goal is to empower people to take control of their personal finances through strategic tools and insights. <Link to="/contact">Contact us</Link>
                     </p>
                  </div>
               </div>
            </div>
            <hr className="bg-white" style={{ height: 2 }} />
            <div className="row text-center justify-content-center align-items-center w-100">
               <div className="logo col-md-4">
                  <img src={logo} alt="website logo" className="w-50" loading="lazy" />
               </div>
               <ul className="list-unstyled py-3 col-md-4">
                  <Link to="/terms-of-use">
                     <li className="d-inline-block mx-2">Terms</li>
                  </Link>
                  <Link to="/privacy">
                     <li className="d-inline-block mx-2">Privacy</li>
                  </Link>
                  <Link to="/cookies">
                     <li className="d-inline-block mx-2">Cookies</li>
                  </Link>
               </ul>
               <div className="socialIcons col-md-4">
                  <Link to="/" className="me-3 fa-brands fa-facebook-f fs-4"></Link>
                  <Link to="/" className="me-3 fa-brands fa-linkedin-in fs-4"></Link>
                  <Link to="/" className="me-3 fa-brands fa-twitter fs-4"></Link>
               </div>
            </div>
         </div>
      </footer>
   );
}
