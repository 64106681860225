import React from 'react';
import logo from '../../images/logo.svg';
export default function Loading() {
   return (
      <div className="container ">
         <div className="loading-page d-flex flex-column justify-content-center align-items-center min-vh-100">
            <i className="fa-solid main-color fa-3x fa-spin fa-spinner"></i>
            <img src={logo} alt="logo" loading="lazy" />
         </div>
      </div>
   );
}
