import React from 'react';
import { Link } from 'react-router-dom';

export default function WriteEmail() {
   return (
      <div className="bg-light">
         <div className="container">
            <div className="row justify-content-center align-items-center flex-column min-vh-100">
               <div className="shadow-sm bg-white col-10 col-md-6 p-md-5 p-3">
                  <div className="text-start">
                     <b>Forgot password</b>
                     <p className="pt-3 lead">Please enter your email to reset the password</p>
                  </div>
                  <div className="email-input text-start py-3 w-100">
                     <label className="py-2" htmlFor="email-address">
                        Your Email
                     </label>
                     <input type="email" placeholder="Enter your email" className="form-control bg-body-secondary" id="email-address" />
                     <Link to="/check-email">
                        <button className="btn-light rounded my-4">Reset Password</button>
                     </Link>
                     <p>
                        Return to{' '}
                        <Link className="main-color" to="/login">
                           sign in
                        </Link>
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
