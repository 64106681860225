import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import './common.css';
import Logo from './Logo';

export default function SideBar() {
   return (
      <div className="row">
         <div className={`sideBar hide col-lg-2 col-md-3 bg-body-tertiary`}>
            <Link to="/" className="navbar-brand">
               <Logo />
            </Link>
            <ul className="list-unstyled d-flex flex-column px-3 py-5 vh-100">
               <Link className="mb-3 fw-bold main-color" to="/education/dashboard/account">
                  <i className="fa-solid px-2 fa-house"></i>
                  Dashboard
               </Link>
               <span className="my-2 text-muted">Other</span>
               <Link className="my-3 main-color" to="/education/dashboard/store">
                  <i className="fa-solid px-2 fa-store"></i>Store
               </Link>
               <Link className="my-3 main-color" to="/education/dashboard/subscription">
                  <i className="fa-regular px-2 fa-bell"></i>
                  Subscription
               </Link>
               <Link className="my-3 main-color" to="/education/dashboard/setting">
                  <i className="fa-solid px-2 fa-gear"></i>
                  Settings
               </Link>
               <div className="text-center mt-5 w-100">
                  <Link className="main-color" to="/contact">
                     Contact Us
                  </Link>
               </div>
            </ul>
         </div>
         <div className="col col-lg-10 px-4">
            <Outlet />
         </div>
      </div>
   );
}
