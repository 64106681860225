import React from 'react';
import { Link } from 'react-router-dom';

export default function Information(props) {
   return (
      <>
         {props.title && <h3 className="py-2">{props.title}</h3>}
         {props.info && (
            <p className="py-2 text-lowercase">
               {props.boldText && <b className="pe-1">{props.boldText}</b>}
               {props.info}
               {props.light && (
                  <Link to={props.lightTextPath}>
                     <span className="main-color">{props.light}</span>
                  </Link>
               )}
            </p>
         )}
         {props.subtitle && <h6 className="py-2 h5">{props.subtitle}</h6>}
      </>
   );
}
