import React from 'react';
import DashboardNav from '../common/DashboardNav';
import Questions from './Questions';
import PlansCards from '../common/PlansCards';
import PaymentsLogo from './PaymentsLogo';

export default function Subscription() {
   return (
      <>
         <DashboardNav />
         <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
               <button
                  className="nav-link active rounded"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-planning"
                  type="button"
                  role="tab"
                  aria-controls="pills-planning"
                  aria-selected="true">
                  Planning & Pricing
               </button>
            </li>
            <li className="nav-item" role="presentation">
               <button
                  className="nav-link"
                  id="pills-subscription-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-Subscription"
                  type="button"
                  role="tab"
                  aria-controls="pills-Subscription"
                  aria-selected="false">
                  Subscription history
               </button>
            </li>
            <li className="nav-item" role="presentation">
               <button
                  className="nav-link"
                  id="pills-Invoices-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-Invoices"
                  type="button"
                  role="tab"
                  aria-controls="pills-Invoices"
                  aria-selected="false">
                  Invoices
               </button>
            </li>
            <li className="nav-item" role="presentation">
               <button
                  className="nav-link"
                  id="pills-promo-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-promo-code"
                  type="button"
                  role="tab"
                  aria-controls="pills-promo-code"
                  aria-selected="false">
                  Promo Code
               </button>
            </li>
         </ul>
         <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-planning" role="tabpanel" aria-labelledby="pills-planning-tab">
               <div>
                  <PlansCards />
                  <Questions />
               </div>
            </div>
            <div className="tab-pane fade" id="pills-Subscription" role="tabpanel" aria-labelledby="pills-subscription-tab">
               <div>{/* Content for Subscription history tab */}</div>
            </div>
            <div className="tab-pane fade" id="pills-Invoices" role="tabpanel" aria-labelledby="pills-Invoices-tab">
               <div>{/* Content for Invoices tab */}</div>
            </div>
            <div className="tab-pane fade" id="pills-promo-code" role="tabpanel" aria-labelledby="pills-promo-tab">
               <div>
                  <h3>Promo Code</h3>
                  <p className="lead"></p>
                  <div className="promoCode-input">
                     <div className="row g-3 py-3 align-items-center justify-content-start">
                        <div className="col-md-4">
                           <input type="text" placeholder="enter you promo code" className="form-control my-2" />
                        </div>
                        <div className="col-4 col-md-2">
                           <button className="main-color rounded w-100 fw-bold text-capitalize">apply</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
