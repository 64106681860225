import React from 'react';

export default function TokenTable() {
   return (
      <div className="container p-4">
         <h5 className="mb-4">Learn with FinTechFusion</h5>
         <p>Boost your financial knowledge with our essential resources.</p>
         <div className="table-responsive">
            <table className="table table-hover my-5">
               <thead>
                  <tr>
                     <th scope="col">Course</th>
                     <th scope="col">Overview</th>
                     <th scope="col">Level</th>
                     <th scope="col">Action</th>
                  </tr>
               </thead>
               <tbody>
                  <tr className="align-middle">
                     <td>
                        <span>Debt Management</span>
                     </td>
                     <td>Reduce debt and achieve freedom.</td>
                     <td>Beginner</td>
                     <td>
                        <a href="/education/dashboard/store/debt-management" className="btn btn-outline-primary btn-sm">Start</a>
                     </td>
                  </tr>
                  <tr className="align-middle">
                     <td>
                        <span>Income Optimization</span>
                     </td>
                     <td>Maximize income, minimize taxes.</td>
                     <td>Intermediate</td>
                     <td>
                        <a href="/education/dashboard/store/income-optimization" className="btn btn-outline-primary btn-sm">Start</a>
                     </td>
                  </tr>
                  <tr className="align-middle">
                     <td>
                        <span>Investment Planning</span>
                     </td>
                     <td>Plan for long-term growth.</td>
                     <td>Advanced</td>
                     <td>
                        <a href="/education/dashboard/store/investment-planning" className="btn btn-outline-primary btn-sm">Start</a>
                     </td>
                  </tr>
                  <tr className="align-middle">
                     <td>
                        <span>Financial Planning</span>
                     </td>
                     <td>Create a plan for your future.</td>
                     <td>All Levels</td>
                     <td>
                        <a href="/education/dashboard/store/financial-planning" className="btn btn-outline-primary btn-sm">Start</a>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
   );
}
