import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

export default function Checkmail() {
   const [maxLength] = useState(1); // Initial max length for each input
   const inputRef = useRef(null);

   const focusInput = () => {
      if (inputRef.current) {
         inputRef.current.focus();
      }
   };

   useEffect(() => {
      focusInput();
   }, []);

   return (
      <div className="bg-light">
         <div className="container">
            <div className="d-flex flex-column justify-content-center align-items-center min-vh-100">
               <div className="shadow-sm p-md-5 p-3 bg-white ">
                  <div className="text-start ">
                     <b>Check your email</b>
                     <p className="py-1">We sent a reset code to your email you entered the 5-digit code mentioned you the email.</p>
                  </div>
                  <div className="d-flex mt-4 mx-auto justify-content-center">
                     <input ref={inputRef} type="text" className="form-control text-center mx-1" style={{ width: 50 }} maxLength={maxLength} />
                     <input type="text" className="form-control text-center mx-2" style={{ width: 50 }} maxLength={maxLength} />
                     <input type="text" className="form-control text-center mx-2" style={{ width: 50 }} maxLength={maxLength} />
                     <input type="text" className="form-control text-center mx-2" style={{ width: 50 }} maxLength={maxLength} />
                     <input type="text" className="form-control text-center mx-2" style={{ width: 50 }} maxLength={maxLength} />
                  </div>
                  <div className="d-flex justify-content-center flex-column">
                     <Link to="/new-password" className="mx-auto mt-3 text-center">
                        <button className="btn-light rounded px-5">Verify Code</button>
                     </Link>
                     <p className="pt-4 text-center">
                        Haven’t got the email yet? <span className="main-color">Resend email</span>
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
