import React from 'react';

export default function Questions() {
   return (
      <div className="Quastion">
         <div className="title border-bottom">
            <h5>Any Quastion</h5>
         </div>
         <div className="accordion accordion-flush py-4" id="accordionFlushExample">
            <div className="accordion-item">
               <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                     <p className="main-color fs-6 fw-bold"> How secure is Fintech Fusion?</p>
                  </button>
               </h2>
               <div id="flush-collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                   Security is our top priority. FinTechFusion employs state-of-the-art encryption and security protocols to protect your data.

                  </div>
               </div>
            </div>
            <div className="accordion-item">
               <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                     <p className="main-color fs-6 fw-bold"> How do I start using Fintech Fusion?</p>
                  </button>
               </h2>
               <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    To Start Using FinTechFusion, Simply Sign Up For An Account On Our Platform, Verify Your Account. Once Your Account Is Set Up, You Can Select course that fit you best to start learning.
                  </div>
               </div>
            </div>
            <div className="accordion-item">
               <h2 className="accordion-header">
                  <button
                     className="accordion-button collapsed"
                     type="button"
                     data-bs-toggle="collapse"
                     data-bs-target="#flush-collapseThree"
                     aria-expanded="false"
                     aria-controls="flush-collapseThree">
                     <p className="main-color fs-6 fw-bold"> What is Fintech Fusion?</p>
                  </button>
               </h2>
               <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                     FinTechFusion revolutionizes the way you invest, take command of your overall financial health.
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
