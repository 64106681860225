import React from 'react';
import botImg from '../images/bot.png';
import botLogo from '../images/botLogo.png';
import PageHead from '../common/PageHead';
import Pagebody from '../common/Pagebody';
import PageFooter from '../common/PageFooter';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
import ScrollToTop from '../hooks/useScroll';

export default function Botai() {
   ScrollToTop();
   return (
      <>
         <Navbar />
         <div className="container">
            <PageHead
               title="Bot Ai"
               p1="Bot AI optimizes trading with intelligent algorithms, enhancing the traditional Dollar Cost Averaging approach by dynamically responding to market changes. This method not only averages your investment cost but also strategically times the market for entry and exit."
               p2="For instance, investing a large amount at once risks high entry points, potentially leading to losses if the market dips. Bot AI, however, evaluates market conditions in real-time, deciding the best moments to distribute your investment across different price levels."
               p3="Bot AI buys at different prices, getting you a better average cost and cutting the risk of market ups and downs. With smart analysis and planning, it aims for smarter, safer investments by following market trends."
               btnContent="get start"
               image={botImg}
            />
            <Pagebody
               title="Ideal for Traders of All Skills"
               info="Explore Bot AI"
               spanContent="Consider this scenario:"
               p1="You're investing $5,000. With Bot AI's expert guidance, it skillfully invests your capital over five months."
               p2="If prices at the time of each entry were $100, $75, $65, $55, and $60, your average asset price would be the average cost of entry at $70."
               p3="If you had invested all your money at once, you would have paid $100 per share, almost 30% more. Bot AI's strategy helps lower your average cost and increase your profits by smartly navigating market trends."
               head1="PRICE WITH BOT AI"
               price1="$70.00"
               head2="PRICE WITHOUT BOT AI"
               price2="$100.00"
            />
            <PageFooter title="Ready-to-use Bots" info="Steps to Configure a Bot Analysis Strategy" botLogo={botLogo} />
         </div>
         <Footer />
      </>
   );
}
