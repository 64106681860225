import React from 'react';
import serv1 from '../../images/serv1.png';
import serv2 from '../../images/serv2.png';
import serv3 from '../../images/serv3.png';
import serv4 from '../../images/serv4.png';
import serv5 from '../../images/serv5.png';
import serv6 from '../../images/serv6.png';
import serv7 from '../../images/serv7.png';
import serv8 from '../../images/serv8.png';
import serv9 from '../../images/serv9.png';

export default function Services() {
   const cardsInfo = [
      {
         title: 'Debt Management',
         info: 'Break free from debt and build financial freedom.',
         serv1,
      },
      {
         title: 'Financial Planning',
         info: 'Secure your future with comprehensive financial planning.',
         serv2,
      },
      {
         title: 'Analytics',
         info: "Maximize your earnings and minimize tax burdens.",
         serv3,
      },
      {
         title: 'Debt Management',
         info: 'Break free from debt and build financial freedom.',
         serv4,
      },
      {
         title: 'Financial Planning:',
         info: 'Setting financial goals, estate planning, retirement planning.',
         serv5,
      },
      {
         title: 'Dashboard',
         info: 'Manage your courses easily. See your daily online material.',
         serv6,
      },
      {
         title: 'Budgeting and Saving',
         info: 'Creating a budget, saving for emergencies, building wealth.',
         serv7,
      },
      {
         title: 'Risk Management',
         info: 'Learn how to protect your money.',
         serv8,
      },
      {
         title: 'account',
         info: 'Create a account and start taking classes .',
         serv9,
      },
   ];
   return (
         <div className="cards pt-3">
            <div className="title py-2 ">
               <h2 className="lh-sm">The right tools for personal improvement.</h2>
            </div>
            <div className="row g-5 py-5">
               {cardsInfo.slice(0, 3).map((el, index) => {
                  return (
                     <div className="col-md-4" key={index}>
                        <div className="h-100 text-start">
                           <div className="content">
                              <div className="card-img">
                                 <img src={el[`serv${index + 1}`]} alt={el.title} className="w-25" style={{ height: 100 }} />
                              </div>
                              <div className="card-title">
                                 <h5 className="fw-bold w-75">{el.title}</h5>
                              </div>
                              <p className="text-muted w-75 py-3">{el.info}</p>
                           </div>
                        </div>
                     </div>
                  );
               })}
            </div>
            <div className="items">
               <div className="title py-2 ">
                  <h2 className="lh-sm">The right tools for personal improvement.</h2>
               </div>
               <div className="row g-5 py-5">
                  {cardsInfo.slice(3, 9).map((el, index) => {
                     return (
                        <div className="col-md-4" key={index}>
                           <div className="h-100">
                              <div className="content text-center">
                                 <div className="card-img text-center">
                                    <img src={el[`serv${index + 4}`]} alt={el.title} className="w-25" style={{ height: 100 }} />
                                 </div>
                                 <div className="card-title">
                                    <h5 className="fw-bold">{el.title}</h5>
                                 </div>
                                 <p className="text-muted py-3">{el.info}</p>
                              </div>
                           </div>
                        </div>
                     );
                  })}
               </div>
            </div>
         </div>
   );
}
