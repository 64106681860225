import React, { useEffect } from 'react';
import Navbar from '../components/navbar/Navbar';
import Information from '../common/Information';
import contactImage from '../images/contact.svg';
import Footer from './../components/footer/Footer';

export default function Contact() {
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <div>
         <Navbar />
         <div className="container py-5">
            <div className="row pb-5 justify-content-center align-items-center">
               <div className="col-md-6">
                  <Information title="Contact Us" info="Welcome to FinTech Fusion, where we’re redefining personal finance and investment for a brighter, more empowered future!" />
                  <Information info="Available 24/7 to serve our customers and answer any potential queries, we recommend users head to our self-service help center for immediate assistance. In the unlikely event, a question is not answered, we advise our customers to contact our support team via the in-App support widget — but please be aware that our response time may be temporarily delayed due to an influx of customers." />
               </div>
               <div className="offset-md-2 col-md-4">
                  <img src={contactImage} alt="contact_image" className="w-100" loading="lazy" />
               </div>
            </div>
            <section className="contact-form mt-5">
               <h2>Contact Us</h2>
               <section className="mb-4">
                  <p className="text-start mb-5">Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within a matter of hours to help you.</p>
                  <div className="row justify-content-between">
                     <div className="col-md-9 mb-md-0 mb-5">
                        <form id="contact-form" name="contact-form" action="mail.php" method="POST">
                           <div className="row">
                              <div className="col-md-6">
                                 <div className="md-form mb-2">
                                    <label htmlFor="name" className="mb-2">Your name</label>
                                    <input type="text" id="name" name="name" className="form-control bg-body-tertiary" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="md-form mb-2">
                                    <label htmlFor="email" className="mb-2">Your email</label>
                                    <input type="text" id="email" name="email" className="form-control bg-body-tertiary" />
                                 </div>
                              </div>
                           </div>
                           <div className="row">
                              <div className="col-md-12">
                                 <div className="md-form mb-2">
                                    <label htmlFor="phone" className="mb-2">Phone</label>
                                    <input type="text" id="phone" name="phone" className="form-control bg-body-tertiary" />
                                 </div>
                              </div>
                           </div>
                           <div className="row">
                              <div className="col-md-12">
                                 <div className="md-form mb-2">
                                    <label htmlFor="address" className="mb-2">Address</label>
                                    <input type="text" id="address" name="address" className="form-control bg-body-tertiary" />
                                 </div>
                              </div>
                           </div>
                           <div className="row">
                              <div className="col-md-12">
                                 <div className="md-form">
                                    <textarea placeholder="Type your message" id="message" name="message" rows="4" className="form-control md-textarea bg-body-tertiary"></textarea>
                                 </div>
                              </div>
                           </div>
                           <button className="btn-light my-3 text-capitalize">Send Request</button>
                           <p>By clicking <b>Send Message</b> you agree to <span className="main-color">Terms of Use</span> and <span className="main-color">Privacy Policy</span></p>
                        </form>
                     </div>
                  </div>
               </section>
            </section>
         </div>
         <Footer />
      </div>
   );
}
