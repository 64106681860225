import React from 'react';
import logo from '../../images/logo.svg';
import { Link, useLocation } from 'react-router-dom';
import Financial from "../../pages/Financial_journey";

export default function Navbar() {
   const { pathname } = useLocation();
   return (
      <nav className="navbar navbar-expand-lg shadow-sm">
         <div className="container-fluid align-items-center">
            <Link className="logo" to="/">
               <img src={logo} alt="website logo" className="pt-1" loading="lazy" />
            </Link>
            <button
               className="navbar-toggler"
               type="button"
               data-bs-toggle="collapse"
               data-bs-target="#navbarSupportedContent"
               aria-controls="navbarSupportedContent"
               aria-expanded="false"
               aria-label="Toggle navigation">
               <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse ms-3 ms-md-5" id="navbarSupportedContent">
               <ul className="navbar-nav mx-auto mb-lg-0">
                  <li className="nav-item">
                     <Link className="nav-link main-color" to="/">
                        Home
                     </Link>
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link main-color" to="/Financial">
                       Financial journey
                     </Link>
                  </li>
                  <li className="nav-item">
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link main-color" to="/plans">
                        Plans
                     </Link>
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link main-color" to="/about">
                        About us
                     </Link>
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link main-color">FAQ</Link>
                  </li>
               </ul>
               <div className="nav-btns">
                  {pathname !== '/login' && pathname !== '/signup' ? (
                     <Link to="/login">
                        <button className="main-btn me-3 px-3 rounded">login</button>
                     </Link>
                  ) : (
                     ''
                  )}
                  <Link to="/signup">
                     <button className="main-btn px-3 rounded">Try Free</button>
                  </Link>
               </div>
            </div>
         </div>
      </nav>
   );
}
