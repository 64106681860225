import React from 'react';
import PageHead from '../common/PageHead';
import botImg from '../images/bot.png';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
import ScrollToTop from '../hooks/useScroll';
export default function Financial() {
   ScrollToTop();

   return (
      <>
         <Navbar />
         <div className="container">
            <PageHead
               title="Empower your financial journey"
               p1="Empower your financial journey with FinTech Fusion's tailored plans. Our Basic Plan provides a solid foundation in fintech fundamentals, making it ideal for beginners. For those seeking to deepen their knowledge and skills"
               p2="our Essential Plan offers expanded coursework and expert insights. To achieve comprehensive financial mastery and unlock exclusive benefits, our Premium Plan is the ultimate choice"
               p3="Each plan is meticulously designed to cater to your specific needs and aspirations, ensuring you have the tools and support to reach your financial goals."
               btnContent="get start"
            />
         </div>
         <Footer />
      </>
   );
}
