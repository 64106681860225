import React from 'react'
import activeImg from '../images/activateimg.png'
import botImg from '../images/botassist.png';
import demoAcc from '../images/demoacc.png';
import advategeImg from '../images/advantage.png';
import stratgyImg from '../images/strategy.png';

export default function Activate() {
  return (
     <div className="container ">
        <div className="activate-Title">
           <div className="d-flex align-items-center justify-content-start pt-5">
              <h3 className="mt-2">3-Day Trial Activated</h3>
              <img src={activeImg} alt="activate-img" className="mx-3" style={{ width: 35 }} loading="lazy" />
           </div>
        </div>
        <p>Thank you for choosing FinTechFusion! During your trial, you’ll enjoy:</p>
        <div className="row w-75 py-2">
           <div className="col-md-6 d-flex align-items-center my-2">
              <img src={stratgyImg} alt="activate-img" className="me-3" loading="lazy" />
              <div className="info">
                 <b>All type of bots</b>
                 <p className="main-color">Bot Analysis & Bot AI</p>
              </div>
           </div>
           <div className="col-md-6 d-flex align-items-center my-2">
              <img src={demoAcc} alt="activate-img" className="me-3" loading="lazy" />
              <div className="info">
                 <b>Demo Account</b>
                 <p className="main-color">Risk free way to gain hands-on trading experience</p>
              </div>
           </div>
           <div className="col-md-6 d-flex align-items-center my-2">
              <img src={advategeImg} alt="activate-img" className="me-3" loading="lazy" />
              <div className="info">
                 <b>Advanced features</b>
                 <p className="main-color">multiple strategy, Trailing Stop Loss, Futures trading and more</p>
              </div>
           </div>
           <div className="col-md-6 d-flex align-items-center my-2">
              <img src={stratgyImg} alt="activate-img" className="me-3" loading="lazy" />
              <div className="info">
                 <b>Pre-built strategies</b>
                 <p className="main-color">Convenient and time-saving solution for trading</p>
              </div>
           </div>
        </div>
        <button className="main-btn rounded px-5 py-2">Start</button>
     </div>
  );
}
