import React from 'react'
import logo from '../images/logo.svg';

export default function Logo() {
  return (
     <div className="position-relative top-0 start-0 bg-body-tertiary w-100">
        <img src={logo} className='w-100' alt="Logo" />
     </div>
  );
}
