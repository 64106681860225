import React from 'react';
import InfoCard from '../../common/InfoCard';

export default function HeaderTextInfo() {
   return (
         <div className="row">
            <div className="col-md-12">
               <div className="info">
                  <h2>FinTechFusion: Learn in Any Time</h2>
                  <p className="text-muted py-2">fintechfusion revolutionizes the way you invest, take command of your overall financial health by focusing on these essential pillars: strategic debt management to eliminate financial burdens and achieve financial freedom, income .</p>
               </div>
            </div>
               <InfoCard heading="Financial Planning" text="Secure your future with comprehensive financial planning." />
               <InfoCard heading="Income and Taxes" text="Maximize your earnings and minimize tax burdens." />
               <InfoCard heading="Debt Management" text="Break free from debt and build financial freedom." />
         </div>
   );
}
