import React from 'react';

export default function InfoBox(props) {
  return (
    <div className="row justify-content-around align-items-center shadow-sm">
      <div className="col-md-6">
        <h3 className="py-2">{props.title}</h3>
        <p className="text-muted">{props.info}</p>
        <div className="btns my-2">
          <button className="main-btn rounded">{props.btn1Content}</button>
          {props.btn2Content && props.btn2Link ? (
            <a href={props.btn2Link}>
              <button className="btn-light opacity-75 rounded ms-2">
                {props.btn2Content}
              </button>
            </a>
          ) : (
            props.btn2Content && (
              <button className="btn-light opacity-75 rounded ms-2">
                {props.btn2Content}
              </button>
            )
          )}
        </div>
      </div>
      <div className="col-md-5">
        {props.image && <img src={props.image} className="w-100" alt="demo_image" />}
      </div>
    </div>
  );
}
