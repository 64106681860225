import React from 'react'

export default function FilterBtns(props) {
  return (
     <div className="filter-btns py-2">
        <button className="btn-light rounded text-white">{props.btn1}</button>
        <button className="btn-light rounded text-white m-2">{props.btn2}</button>
        <button className="btn-light rounded text-white ">{props.btn3}</button>
     </div>
  );
}
