import React from 'react';
import chartImg from '../images/Chart-container.webp';
import markArea from '../images/Mark-area.png';

export default function ChartContainer(props) {
  return (
     <div className="container py-4">
        <div className="row align-items-center">
           <div className="row align-items-center">
              <div className="heading-box col-md-10">
                 <b>{props.Title}</b>
              </div>
              <div className="checks-box d-flex col-md-2">
                 <div className="usd-checkbox d-flex align-items-center">
                    <input type="checkbox" defaultChecked className="form-check-input" />
                    <label className="mx-2 mt-1">Beginner</label>
                 </div>
                 <div className="btc-checkbox d-flex align-items-center">
                    <input type="checkbox" className="form-check-input" />
                    <label className="mx-2 mt-1">Advanced</label>
                 </div>
              </div>
           </div>

           {/* Financial Education Area */}
           <div className="col-md-3">
              <img src={chartImg} className="w-75" alt="financial-topic" />
           </div>
           <div className="offset-md-1 col-md-2">
              <div className="text-start">
                 <p className="main-color">Course Overview</p>
                 <h5 className="py-3 main-color">
                    <span className="text-black">Income Optimization</span>
                 </h5>
                 <span className="main-color">Level: Intermediate</span>
              </div>
           </div>

           <div className="col-md-6">
              <img src={markArea} alt="financial-plan" className="w-100" />
              <div className="monthes-data m-4 text-center main-color">
                 <span className="mx-2">Basics</span>
                 <span className="mx-2">Growth</span>
                 <span className="mx-2">Planning</span>
                 <span className="mx-2">Investment</span>
                 <span className="mx-2">Security</span>
              </div>
           </div>
        </div>
     </div>
  );
}
