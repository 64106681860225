import React from 'react';
import { useParams } from 'react-router-dom';
import DebtManagementImage from '../images/Debt-Management.png';
import IncomeOptimizationImage from '../images/Income-Optimization.png';
import InvestmentPlanningImage from '../images/Investment-Planning.png';
import FinancialPlanningImage from '../images/Financial-Planning.png';
import RetirementStrategiesImage from '../images/Retirement-Strategies.png';
import WealthBuildingImage from '../images/Wealth-Building.png';
import RiskManagementImage from '../images/Risk-Management.png';
import TaxPlanningImage from '../images/Tax-Planning.png';
import EstatePlanningImage from '../images/Estate-Planning.png';

export default function DetailPage() {
   const { id } = useParams();

   // Define the course details
   const itemDetails = {
      "debt-management": {
         image: DebtManagementImage,
         description: 'Learn strategies to reduce debt and achieve financial freedom.',
         whatsNew: ["Debt reduction techniques", "Managing financial risks", "Creating a debt repayment plan"],
         rating: 4.5,
         users: "200+ users",
         price: "Free",
         videos: [
            { title: "Strategies for Reducing Debt", url: "https://www.youtube.com/embed/CU4l_rs50Kk" }
         ]
      },
      "income-optimization": {
         image: IncomeOptimizationImage,
         description: 'Maximize your income and minimize taxes with smart financial planning.',
         whatsNew: ["Increasing passive income streams", "Tax optimization strategies", "Improving financial literacy"],
         rating: 4.7,
         users: "150+ users",
         price: "Free",
         videos: [
            { title: "Financial Planning 101 - Winning Financially", url: "https://www.youtube.com/embed/LeY_qBCVWRs" }
         ]
      },
      "investment-planning": {
         image: InvestmentPlanningImage,
         description: 'Plan for long-term growth and secure your financial future.',
         whatsNew: ["Setting investment goals", "Building a diversified portfolio", "Understanding risk tolerance"],
         rating: 4.8,
         users: "250+ users",
         price: "Free",
         videos: [
            { title: "Optimal Order of Investing for Retirement", url: "https://www.youtube.com/embed/TLkLSAb0iJc" }
         ]
      },
      "financial-planning": {
         image: FinancialPlanningImage,
         description: 'Create a comprehensive plan for your future financial goals.',
         whatsNew: ["Setting short and long-term goals", "Creating a savings plan", "Understanding budgeting techniques"],
         rating: 4.6,
         users: "180+ users",
         price: "Free",
         videos: [
            { title: "Retirement Planning: Strategies for a Secure Future", url: "https://www.youtube.com/embed/tiVAJFzNlys" }
         ]
      },
      "retirement-strategies": {
         image: RetirementStrategiesImage,
         description: 'Explore different approaches to build a comfortable retirement fund.',
         whatsNew: ["Planning retirement income", "Choosing the right retirement accounts", "Understanding social security"],
         rating: 4.9,
         users: "300+ users",
         price: "Free",
         videos: [
            { title: "4 Simple Retirement Income Strategies", url: "https://www.youtube.com/embed/bACANtbmGs4" }
         ]
      },
      "wealth-building": {
         image: WealthBuildingImage,
         description: 'Learn techniques to build and manage your wealth effectively.',
         whatsNew: ["Creating wealth-building habits", "Investing for growth", "Understanding compound interest"],
         rating: 4.7,
         users: "220+ users",
         price: "Free",
         videos: [
            { title: "How Millionaires Build Wealth! (With Dave Ramsey)", url: "https://www.youtube.com/embed/JZSKNKBprkY" }
         ]
      },
      "risk-management": {
         image: RiskManagementImage,
         description: 'Understand and mitigate financial risks in your investments.',
         whatsNew: ["Assessing investment risks", "Diversification strategies", "Risk management tools"],
         rating: 4.5,
         users: "190+ users",
         price: "Free",
         videos: [
            { title: "Case Studies in Retirement Income Planning", url: "https://www.youtube.com/embed/BrV671r4kQY" }
         ]
      },
      "tax-planning": {
         image: TaxPlanningImage,
         description: 'Optimize your tax strategies to retain more of your earnings.',
         whatsNew: ["Tax-saving investments", "Understanding deductions", "Tax-efficient withdrawal strategies"],
         rating: 4.4,
         users: "170+ users",
         price: "Free",
         videos: [
            { title: "Tax-Smart Wealth Transfer Strategies", url: "https://www.youtube.com/embed/xuuqtFPqIxo" }
         ]
      },
      "estate-planning": {
         image: EstatePlanningImage,
         description: 'Ensure your assets are transferred according to your wishes.',
         whatsNew: ["Creating a will", "Setting up trusts", "Understanding inheritance taxes"],
         rating: 4.6,
         users: "160+ users",
         price: "Free",
         videos: [
            { title: "Retirement Income Planning | Fidelity Investments", url: "https://www.youtube.com/embed/AgjPM3Ad204" }
         ]
      }
   };

   const item = itemDetails[id];

   if (!item) {
      return <p>Item not found.</p>;
   }

   return (
      <div className="container">
         <div className="card mt-4 p-3">
            <div className="d-flex justify-content-between align-items-center">
               <div style={{ flex: 1 }}>
                  <h3 className="card-title" style={{ textTransform: 'capitalize' }}>{id.replace(/-/g, ' ')}</h3>
                  <p>{item.description}</p>
                  <div className="d-flex justify-content-start align-items-center mb-2">
                     <span>⭐ {item.rating}</span>
                     <span className="mx-3">{item.users}</span>
                  </div>
                  <button className="btn btn-primary">{item.price}</button>
               </div>
               <div style={{ flex: '0 0 30%', textAlign: 'right' }}>
                  <img
                     src={item.image}
                     alt={id}
                     style={{
                        width: '100%',
                        height: 'auto',
                        maxWidth: '200px',
                        objectFit: 'contain'
                     }}
                  />
               </div>
            </div>
         </div>

         {/* Course Video Sections */}
         <div className="mt-4">
            <h4>Course Videos</h4>
            {item.videos.map((video, index) => (
               <div key={index} className="mb-4">
                  <h5>{video.title}</h5>
                  <div style={{
                     position: 'relative',
                     paddingTop: '56.25%', // 16:9 Aspect Ratio
                     borderRadius: '10px',
                     overflow: 'hidden',
                     boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
                     marginBottom: '1rem',
                  }}>
                     <iframe
                        src={video.url}
                        style={{
                           position: 'absolute',
                           top: 0,
                           left: 0,
                           width: '100%',
                           height: '100%',
                           border: 'none'
                        }}
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title={video.title}
                     ></iframe>
                  </div>
               </div>
            ))}
         </div>

         <div className="mt-4">
            <h4>What's New</h4>
            <ul>
               {item.whatsNew.map((update, index) => (
                  <li key={index}>{update}</li>
               ))}
            </ul>
         </div>
      </div>
   );
}
