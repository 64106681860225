import React from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';

export default function Notfound() {
  return (
     <>
     <Navbar/>
        <div className="container ">
           <div className="d-flex justify-content-center align-items-center vh-100">
              <div className="heading-InfoBox text-center mb-5">
                 <h2>opps !</h2>
                 <p className="lead main-color fw-bold py-3">this page is not found</p>
                 <Link to="/">
                    <button className="main-btn rounded">Go back</button>
                 </Link>
              </div>
           </div>
        </div>
        <Footer/>
     </>
  );
}
