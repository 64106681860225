import React from 'react';
export default function PageFooter(props) {
   return (
      <>
         <div className="title">
            <h2 className="py-1">{props.title}</h2>
            <p>{props.info}</p>
         </div>
         <div className="row py-5 text-center text-md-start justify-content-center">
            <div className="image col-md-3 text-center">
               <img src={props.botLogo} className="w-50" alt="botlogo1" />
               <p className="py-2">Choose a bot</p>
            </div>
            <div className="image col-md-3 text-center">
               <img src={props.botLogo} className="w-50" alt="botlogo2" />
               <p className="py-2">Connect a Exchange</p>
            </div>
            <div className="image col-md-3 text-center">
               <img src={props.botLogo} className="w-50" alt="botlogo3" />
               <p className="py-2">Apply Bot strategy</p>
            </div>
            <div className="image col-md-3 text-center">
               <img src={props.botLogo} className="w-50" alt="botlogo4" />
               <p className="py-2">View Stats</p>
            </div>
         </div>
      </>
   );
}
