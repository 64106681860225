import React from 'react';
import { Link } from 'react-router-dom';
import cardImg1 from '../../images/cardImg1.webp';
import cardImg2 from '../../images/cardImg2.webp';
import cardImg3 from '../../images/cardImg3.webp';
import cardImg4 from '../../images/cardImg3.webp';

export default function Cards() {
   const cardsInfo = [
      {
         title: 'Financial Planning',
         info: "With FinTechFusion's courses, set your goal ahead of time with specific triggers. Just  start learning and let FinTechFusion help you learn how to manage your personal finances . \n",
         btnContent: 'signup',
         cardImg1,
      },
      {
         title: 'Income and Taxes',
         info: 'Tax deductions, credits, and exemptions, tax-advantaged accounts, tax-loss harvesting, Finding a side hustle, generating additional income, self-employment taxes.\n',
         btnContent: 'signup',
         cardImg2,
      },
      {
         title: 'Debt Management',
         info: "Understanding interest rates, credit scores, balance transfers, debt consolidation., Types of loans (personal, student, auto, mortgage), interest rates, repayment plans, loan refinancing\n",
         btnContent: 'signup',
         cardImg3,
      },
      {
         title: 'Real Estate',
         info: "Mortgage process, down payments, closing costs, home inspections,Cost analysis, tax benefits, lifestyle considerations.\n",
         btnContent: 'signup',
         cardImg4,
      },
   ];

   return (
         <div className="cards pt-3">
            <div className="title py-2 ">
               <h2 className="lh-sm">FinTechFusion makes Learning easier</h2>
            </div>
            <div className="row g-5 py-5">
               {cardsInfo.map((el, index) => {
                  return (
                     <div className="col-md-6" key={index}>
                        <div className="card py-3 ps-3 position-relative bg-gray">
                           <div className="card-img">
                              <img src={el[`cardImg${index + 1}`]} alt={el.title} className="position-absolute end-0 bottom-0 w-25" />
                           </div>
                           <div className="card-body ">
                              <div className="card-title h-25">
                                 <h4 className="fw-bold w-75">{el.title}</h4>
                              </div>
                              <p className="text-muted w-75 py-2 ">{el.info}</p>
                              <Link to="/signup">
                                <button className="main-btn rounded">{el.btnContent}</button>
                              </Link>
                           </div>
                        </div>
                     </div>
                  );
               })}
            </div>
         </div>
   );
}
