import React from 'react'
import LoginImg from '../images/loadingImg.png';

export default function LoginImage(props) {
  return (
     <div className="col-md-6 text-center">
        <div className="image pb-2">
           <img src={LoginImg} alt="loginimage" className='w-75' loading="lazy" />
        </div>
        <div className="image-info">
           <h4 className="w-75 mx-auto py-2">{props.title}</h4>
           <p className="text-muted">{props.description}</p>
        </div>
     </div>
  );
}
