import React from 'react';

export default function Plancard(props) {
   return (
      <div className="col-md-3">
         <div className={`plan-card ${props.className} rounded p-2`}>
            <div className="card-head position-relative">
               {props.className.includes('Most_Popular') ? (
                  <span className="badge text-bg-success p-2 position-absolute end-0 " style={{ height: 30, top: -25 }}>
                     Most Popular
                  </span>
               ) : (
                  ''
               )}
               <p className="level">{props.level}</p>
               <p className="duration fs-5 d-inline-block">{props.duration}</p>
               <span className="bg-body-secondary ms-4 px-3 py-1 rounded ">{props.disount}</span>
               <p className="py-2">{props.text}</p>
               <button className="main-btn px-4 rounded">buy</button>
            </div>
            <div className="card-body-content">
               <ul className="list-unstyled py-4">
                  <li className="py-2">{props.l1}</li>
                  <li className="py-2">{props.l2}</li>
                  <li className="py-2">{props.l3}</li>
                  <li className="py-2">{props.l4}</li>
                  <li className="py-2">{props.l5}</li>
                  <li className="py-2">{props.l6}</li>
               </ul>
            </div>
            <div className="card-footer pb-4">
               <p className="main-color">{props.text_footer1}</p>
               <p className="main-color">{props.text_footer2}</p>
            </div>
         </div>
      </div>
   );
}
