import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import waveImg from '../images/waving-hand.png';

export default function Signup() {
   const [firstName, setFirstName] = useState('');
   const [lastName, setLastName] = useState('');
   const [email, setEmail] = useState('');
   const [phone, setPhone] = useState('');
   const [password, setPassword] = useState('');
   const [error, setError] = useState({});

   const handleSignup = () => {
      const newErrors = {};

      // Validation checks
      if (firstName.length < 3) newErrors.firstName = "First name must be at least 3 characters.";
      if (lastName.length < 3) newErrors.lastName = "Last name must be at least 3 characters.";
      if (!email.includes('@')) newErrors.email = "Please enter a valid email.";
      if (phone.length < 10) newErrors.phone = "Phone number must be at least 10 digits.";
      if (password.length < 6) newErrors.password = "Password must be at least 6 characters.";

      if (Object.keys(newErrors).length > 0) {
         setError(newErrors);
      } else {
         alert("You cannot sign up. Please contact the admin for assistance.");
      }
   };

   return (
      <div className="container">
         <div className="row justify-content-center align-items-center min-vh-100 py-3">
            <div className="col-md-5 offset-md-1">
               <div className="info py-3">
                  <img src={waveImg} alt="wave-img" style={{ width: 25 }} />
                  <h5 className="fw-bold py-2">Welcome!</h5>
                  <p>Please sign up to access your site.</p>
               </div>
               <div className="form-inputs">
                  <div className="first_name pb-2">
                     <label htmlFor="first_name" className="form-label fs-5">
                        First name
                     </label>
                     <input
                        type="text"
                        id="first_name"
                        placeholder="First name"
                        className="form-control bg-body-secondary"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                     />
                     {error.firstName && <p className="text-danger">{error.firstName}</p>}
                  </div>
                  <div className="last_name pb-2">
                     <label htmlFor="last_name" className="form-label fs-5">
                        Last name
                     </label>
                     <input
                        type="text"
                        id="last_name"
                        placeholder="Last name"
                        className="form-control bg-body-secondary"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                     />
                     {error.lastName && <p className="text-danger">{error.lastName}</p>}
                  </div>
                  <div className="email-input pb-2">
                     <label htmlFor="email" className="form-label fs-5">
                        E-mail
                     </label>
                     <input
                        type="email"
                        id="email"
                        placeholder="Type your e-mail"
                        className="form-control bg-body-secondary"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                     />
                     {error.email && <p className="text-danger">{error.email}</p>}
                  </div>
                  <div className="phone-input pb-2">
                     <label htmlFor="phone" className="form-label fs-5">
                        Phone
                     </label>
                     <input
                        type="tel"
                        id="phone"
                        placeholder="Phone number"
                        className="form-control bg-body-secondary"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                     />
                     {error.phone && <p className="text-danger">{error.phone}</p>}
                  </div>
                  <div className="password-input pb-3">
                     <label htmlFor="password" className="form-label fs-5">
                        Password
                     </label>
                     <input
                        type="password"
                        id="password"
                        placeholder="Type your password"
                        className="form-control bg-body-secondary"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                     />
                     {error.password && <p className="text-danger">{error.password}</p>}
                  </div>
                  <div className="policy d-flex justify-content-between align-content-center">
                     <p>
                        I agree to the site's terms of use and{' '}
                        <Link className="main-color fw-bold" to="/privacy">
                           privacy policy
                        </Link>
                     </p>
                     <input type="checkbox" className="form-check-input" />
                  </div>
                  <button onClick={handleSignup} className="main-btn rounded my-3 w-100">
                     Sign Up
                  </button>
                  <p className="text-start">
                     Already have an account?{' '}
                     <Link to="/login" className="main-color fw-bold">
                        Log in
                     </Link>
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
}
