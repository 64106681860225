import React from 'react';

export default function PageHead(props) {
   return (
      <div className="row py-5">
         <div className="col-md-6">
            <h2 className="py-3">{props.title}</h2>
            <p>{props.p1}</p>
            <p className="py-3">{props.p2}</p>
            <p>{props.p3}</p>
            <button className="main-btn rounded py-1 my-2 px-4 fs-5 text-capitalize">{props.btnContent}</button>
         </div>
         <div className="col-md-4 offset-md-2">
            <img src={props.image} alt={props.title} />
         </div>
      </div>
   );
}
