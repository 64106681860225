import React, { useState } from 'react';
import binanceLogo from '../images/binanice.png';
import { Link } from 'react-router-dom';

export default function BinancePage(props) {
   const [ishovered, setishovered] = useState(false);
   function isMouseEnter() {
      setishovered(true);
   }
   function isMouseLeave() {
      setishovered(false);
   }
   return (
      <div className={`conatiner ${props.className}`}>
         <div className="d-flex flex-column align-items-center justify-content-center min-vh-100">
            <h3 className="py-2 my-5">Connect account to start trading</h3>
            <div onMouseEnter={isMouseEnter} onMouseLeave={isMouseLeave} className={`binanceImage mt-2 text-center my-5 ${ishovered ? 'border-light' : ''}`}>
               <img src={binanceLogo} alt="binance logo" className="w-75" loading="lazy" />
            </div>
            <div className="binance-info mt-3 text-center">
               <p className="py-2">I don’t have an exchange</p>
               <Link to="/binance/connect" className="main-color fw-bold">
                  Start Trading on the Demo
               </Link>
            </div>
         </div>
         <div className="d-flex justify-content-center align-items-center mb-5 text-center">
            <p className="mx-2 lead">
               <i className="fa-solid fa-lock"></i>
               <span className="px-2"> FinTechFusion will not have access to transfer or withdraw your assets</span>
            </p>
         </div>
      </div>
   );
}
