import React from 'react';
import Navbar from '../components/navbar/Navbar';
import Information from '../common/Information';
import Footer from './../components/footer/Footer';
import { Link } from 'react-router-dom';
import ScrollToTop from '../hooks/useScroll';

export default function About() {
   ScrollToTop();
   return (
      <>
         <Navbar />
         <div className="container py-5">
            <Information title="About Our Company" info="FinTech Fusion is revolutionizing how people learn about thire presonal money." />
            <Information
               info="Just as FinTechFusion revolutionizes the way you invest, take command of your overall financial health by focusing on these essential pillars: strategic debt management to eliminate financial burdens and achieve financial freedom, income ."
            />
            <Information info="optimization to maximize earnings and minimize tax liabilities, strategic real estate investments for long-term growth and financial security, and comprehensive financial planning to secure your future and reach your financial goals." />
            <Link to="/contact">
               <button className="btn-light my-3">contact us</button>
            </Link>
         </div>
         <Footer />
      </>
   );
}
