import React from 'react';

export default function Pagebody(props) {
   return (
      <div className="row py-5 align-items-center">
         <div className="col-md-6">
            <h2 className="py-3">{props.title}</h2>
            <b>{props.info}</b>
            <span className="d-block my-3">{props.spanContent}</span>
            <p>{props.p1}</p>
            <p className="py-3">{props.p2}</p>
            <p>{props.p3}</p>
         </div>
         <div className="col-md-4 offset-md-2 ">
            <p className="text-text-uppercase">{props.head1}</p>
            <h5 className="fw-bold">{props.price1}</h5>
            <p className="text-text-uppercase">{props.head2}</p>
            <h5 className="fw-bold">{props.price2}</h5>
         </div>
      </div>
   );
}
