import React from 'react';
import Plancard from './Plancard';

export default function PlansCards() {
   return (
      <div className="bg-light">
         <div className="container">
            <ul className="nav nav-pills py-3 d-flex justify-content-center align-items-center" id="pills-tab" role="tablist">
               <li className="nav-item" role="presentation">
                  <button
                     className="nav-link active"
                     id="monthly-tab"
                     data-bs-toggle="pill"
                     data-bs-target="#monthly-content"
                     type="button"
                     role="tab"
                     aria-controls="monthly-content"
                     aria-selected="true">
                     Monthly
                  </button>
               </li>
               <li className="nav-item" role="presentation">
                  <button className="nav-link" id="annual-tab" data-bs-toggle="pill" data-bs-target="#annual-content" type="button" role="tab" aria-controls="annual-content" aria-selected="false">
                     Annual Up to -25%
                  </button>
               </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
               <div className="tab-pane fade show active" id="monthly-content" role="tabpanel" aria-labelledby="monthly-tab">
                  <div className="row justify-content-center g-4 py-5">
                     <Plancard
                        className="border-light"
                        level="Beginner"
                        duration="99AED/month"
                        disount="-25%"
                        l1="Access to introductory courses on fintech basics"
                        l2="Limited access to webinars and live sessions"
                        l3="Access to community forums for discussion"
                        l4="Standard customer support"
                        text_footer1="Beginner option"
                     />
                     <Plancard
                        className="border scale-12 Most_Popular"
                        level="Essential Plan"
                        duration="199AED/month"
                        disount="-25%"
                        l1="Access to all basic courses plus intermediate modules"
                        l2="Monthly webinars with industry professionals"
                        l3="Interactive assignments and case studies"
                        l4="Email and chat support"
                        l5="Certificate of completion for courses"
                        text_footer1="Essential option"
                     />
                     <Plancard
                         className="border-light"
                        level="Premium"
                        duration="299AED/month "
                        disount="-25%"
                        l1="Unlimited access to all courses, including advanced and specialized modules"
                        l2="Weekly webinars and guest lectures from industry leaders"
                        l3="One-on-one mentoring sessions"
                        l4="Priority support"
                        l5="Exclusive workshops and networking events"
                        l6="Premium content such as industry reports and advanced case studies"
                        text_footer1="Premium option"

                     />
                  </div>
               </div>
               <div className="tab-pane fade" id="annual-content" role="tabpanel" aria-labelledby="annual-tab">
                  <div className="row justify-content-center g-4 py-5">
                     <Plancard
                        className="border-light"
                        level="Beginner"
                        duration="990AED/anuual"
                        disount="-25%"
                        l1="Access to introductory courses on fintech basics"
                        l2="Limited access to webinars and live sessions"
                        l3="Access to community forums for discussion"
                        l4="Standard customer support"
                        text_footer1="Beginner option"
                     />
                     <Plancard
                        className="border scale-12 Most_Popular"
                        level="Essential"
                        duration="1990AED/anuual"
                        disount="-25%"
                        l1="Access to all basic courses plus intermediate modules"
                        l2="Monthly webinars with industry professionals"
                        l3="Interactive assignments and case studies"
                        l4="Email and chat support"
                        l5="Certificate of completion for courses"
                        text_footer1="Essential option"

                     />
                     <Plancard
                        className="border-light"
                        level="Premium"
                        duration="2990AED/anuual "
                        disount="-25%"
                        l1="Unlimited access to all courses, including advanced and specialized modules"
                        l2="Weekly webinars and guest lectures from industry leaders"
                        l3="One-on-one mentoring sessions"
                        l4="Priority support"
                        l5="Exclusive workshops and networking events"
                        l6="Premium content such as industry reports and advanced case studies"
                        text_footer1="Premium option"
                     />
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
