import React from 'react';
import headerImg from '../../images/headerimg.webp';
import HeaderTextInfo from '../headerText/HeaderTextInfo';
import Cards from '../cards/Cards';
import Services from '../services/Sevices';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import { Link } from 'react-router-dom';

export default function Header() {
   return (
      <>
         <Navbar />
         <div className="container">
            <div className="row justify-content-center align-items-center py-5">
               <div className="col-md-5">
                  <div className="title">
                     <h1 className="pb-2">Meet your new financial platform</h1>
                     <p className="text-muted py-2">
                        Meet your new financial edge: take new courses . to learn how to be smart, financially , and secure. Our courses will help you find opportunities. We will help you to be good with your day to day expenses so you can be happy with spending money.
                     </p>
                     <Link to="login">
                        <button className="main-btn rounded px-5 mt-3 text-capitalize">start trial</button>
                     </Link>
                  </div>
               </div>
               <div className="offset-md-2 col-md-5 ms-auto">
                  <div className="headerImg">
                     <img src={headerImg} alt="headerImage" />
                  </div>
               </div>
            </div>
            <HeaderTextInfo />
            <Cards />
            <Services />
         </div>
         <Footer />
      </>
   );
}
