import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardNav from '../common/DashboardNav';
import SettingCard from '../common/SettingCard';

export default function Setting() {
   const navigate = useNavigate();
   const [errorMessage, setErrorMessage] = useState('');
   const [successMessage, setSuccessMessage] = useState('');

   const handleBuyNowClick = () => {
      navigate('/education/dashboard/subscription');
   };

   const handleChangeEmailClick = () => {
      setErrorMessage('You cannot change your email right now.');
      setTimeout(() => setErrorMessage(''), 3000); // Clear the error message after 3 seconds
   };

   const handleConnectEmailClick = () => {
      setSuccessMessage('Email is connected successfully.');
      setTimeout(() => setSuccessMessage(''), 3000); // Clear the success message after 3 seconds
   };

   return (
      <>
         <DashboardNav />
         <div className="setting-title p-5">
            <h5>Settings</h5>
            <hr />
            <div className="setting-cards">
               <div className="row py-3 g-3">
                  <SettingCard
                     icon="fa-solid fa-2x main-color fa-user"
                     title="Personal data"
                     text1="ID"
                     value1="128356893"
                     text2="Email"
                     value2="amer@fintechfusion.net"
                     btncontent={<span onClick={handleChangeEmailClick} style={{ cursor: 'pointer' }}>Change Email</span>}
                  />
                  <SettingCard
                     icon="fa-solid fa-2x main-color fa-shield"
                     title="Security"
                     text1="Password"
                     value1="*********"
                     text2="2FA"
                     info="We use Google Authenticator for your safety."
                     btncontent={<span onClick={handleConnectEmailClick} style={{ cursor: 'pointer' }}>Connect Email</span>}
                  />
                  <SettingCard
                     icon="fa-solid fa-2x main-color fa-gear"
                     title="My settings"
                     text1="Language"
                     value1="English"
                     text2="Dark Mode"
                     value2="DisActive"
                  />
                  <SettingCard
                     icon="fa-solid fa-2x main-color fa-receipt"
                     title="Billing"
                     text1="Subscription type"
                     value1="Free"
                     btncontent={<span onClick={handleBuyNowClick} style={{ cursor: 'pointer' }}>Buy Now</span>}
                  />
               </div>
            </div>
            {errorMessage && (
               <div className="alert alert-danger mt-3" role="alert">
                  {errorMessage}
               </div>
            )}
            {successMessage && (
               <div className="alert alert-success mt-3" role="alert">
                  {successMessage}
               </div>
            )}
         </div>
      </>
   );
}
