import React from 'react';

export default function SettingCard(props) {
   return (
      <div className="col-md-6">
         <div className="card p-3 shadow-sm personal-data">
            <div className="d-flex justify-content-start align-items-end">
               <i className={props.icon}></i>
               <h6 className="px-3">{props.title}</h6>
            </div>
            <div className="card-body pt-3">
               <div className="d-flex justify-content-between align-items-center py-1">
                  <span className="main-color lead">{props.text1}</span>
                  <p>{props.value1}</p>
               </div>
               <div className="d-flex justify-content-between align-items-center py-1">
                  <span className="main-color lead">{props.text2}</span>
                  {props.value2 && <p>{props.value2}</p>}
                  {!props.value2 && (
                     <div className="form-check form-switch mb-0">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                     </div>
                  )}
               </div>
               {props.info && <p className="main-color">{props.info}</p>}
               {props.btncontent && <button className="btn-light rounded mt-3">{props.btncontent}</button>}
            </div>
         </div>
      </div>
   );
}
